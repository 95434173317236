// @flow
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  SubmitButton,
  Layout,
  MarkdownPs,
  Section,
  SectionsPartners,
  SectionQuickPrompt,
  Spacer,
  TextHeading,
  Text,
  Input,
} from '../components';
import { Dimen } from '../constants';
import { entries } from '../util';

import type { PageContactQuery } from '../__generated__/PageContactQuery';

type Props = $ReadOnly<{|
  data: PageContactQuery,
|}>;

const PageContact = ({ data: { page, allDatoCmsPartner } }: Props) => {
  if (!page) throw new Error('PageContact missing from CMS');
  if (!allDatoCmsPartner) throw new Error('Missing allDatoCmsPartner');
  const locationLatitude = parseFloat(page.locationLatitude || 0);
  const locationLongitude = parseFloat(page.locationLongitude || 0);

  const mapParams = {
    key: 'AIzaSyCoIOIgzvhtnl1x8MsQddZwKxdeQI5-wuE',
    // q: encodeURIComponent(page.formAddress),
    q: `${locationLatitude},${locationLongitude}`,
    zoom: 14,
  };
  const mapQs = entries(mapParams)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
        </StyledLeadingText>
      </Section>
      <Section backgroundColor="darkBlue">
        <StyledAreaForm>
          <StyledDescription>
            <StyledHeading level={2} tag="h2" invert>
              {page.formTitle}
            </StyledHeading>
            {page.formText && <StyledDescriptionText html={page.formText} />}

            <StyledInfo>
              <div>
                <img src="/img/icons/address.png" />
                <Text>{page.formAddress}</Text>
              </div>
              <div>
                <img src="/img/icons/email.png" />
                <Text>
                  <a
                    href={`mailto:${page.formEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {page.formEmail}
                  </a>
                </Text>
              </div>
            </StyledInfo>

            <StyledSocialIcons>
              <a
                href="https://www.instagram.com/guesthug/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/instagram.png" />
              </a>
              <a
                href="https://twitter.com/guesthug?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/twitter.png" />
              </a>
              <a
                href="https://www.facebook.com/Guesthug/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/facebook.png" />
              </a>
            </StyledSocialIcons>
          </StyledDescription>
          <StyledForm name="contact" method="post" data-netlify>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />

            <Input name="name" placeholder="NAME*" required />
            <Input name="phone" placeholder="PHONE" />
            <Input name="email" placeholder="EMAIL*" required />
            <Input name="message" multiline placeholder="MESSAGE" />
            <SubmitButton label="Submit Now" type="submit" />
          </StyledForm>
          <Spacer flex={1} />
        </StyledAreaForm>
      </Section>
      <StyledMap
        width="600"
        height="450"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/place?${mapQs}`}
        allowFullScreen
      />
      <SectionQuickPrompt
        backgroundColor="lightGreen"
        text="Got a question? Check out our FAQ page"
        button={<Button label="View now" href={'/faq/host'} />}
      />
      <SectionsPartners partnersConnection={allDatoCmsPartner} />
    </Layout>
  );
};
export default PageContact;

export const query = graphql`
  query PageContactQuery {
    page: datoCmsPageContact {
      heading
      formTitle
      formText
      formEmail
      formAddress
      locationLatitude
      locationLongitude
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsPartner {
      ...SectionsPartners_partnersConnection
    }
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledAreaForm = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: ${rem(Dimen.spacing)} 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledDescription = styled('div')`
  flex: 6;
  padding: ${rem(Dimen.spacing)} 0;
`;

const StyledDescriptionText = styled(MarkdownPs)`
  font-size: ${rem(18)};
  max-width: ${rem(400)};
`;

const StyledForm = styled('form')`
  flex: 4;
  max-width: ${rem(500)};
  margin: ${rem(Dimen.spacing)} 0;
`;

const StyledHeading = styled(TextHeading)`
  margin-top: 0;
`;

const StyledInfo = styled('div')`
  margin-top: ${rem(Dimen.spacing / 2)};

  > div {
    margin: ${rem(Dimen.spacing / 4)} 0;
  }

  img {
    width: ${rem(20)};
    margin-right: ${rem(Dimen.spacing / 4)};
  }
`;

const StyledSocialIcons = styled('div')`
  margin-top: ${rem(Dimen.spacing / 2)};

  a {
    transition: opacity 0.25s;
    &:hover {
      opacity: 0.8;
    }
  }

  img {
    width: ${rem(25)};
    display: inline-block;
    margin-right: ${rem(Dimen.spacing / 4)};
  }
`;

const StyledMap = styled('iframe')`
  display: block;
  height: ${rem(600)};
  width: 100%;
`;
